$item-width: 10rem;

.notes {
  &__header {
    padding: 1rem;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
  }
}

.weekly-goal {
  margin-top: 3rem;

  .advancement__options span:not(.current) {
    color: white;
  }
}
