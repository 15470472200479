// -----------------------------------------------------------------------------
// Layout
// Starts with our reset and includes any other base element-level styling.
// -----------------------------------------------------------------------------

* {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  // color: $font-primary-color;
}

.wrapper {
  display: grid;
  height: 100vh;
  width: 100vw;
  // overflow: hidden;
  background: $background;
  font-family: $font-primary;
  font-weight: 400;

  &--roster {
    grid-template-areas:
      "header"
      "roster";
    grid-template-columns: auto;
    grid-template-rows: $header-height 1fr;
  }
}