.header {
    grid-area: header;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;

    &__logo {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 20px;
    }
    &__builddate {
        font-size: 12px;
    }
    &__nav {
        display: flex;
        flex-flow: row nowrap;
        background: $white;
        border-radius: 4rem;
        padding: 0 0.75rem;

        li {
            padding: 0.5rem;
        }

        button {
            padding: 0.5rem;
            margin: 0;
            line-height: 1;
            min-height: unset;
            color: $ifl-darkpurple-3;
            transition: all 0.15s ease-out;
            font-family: $font-primary;
            position: relative;

            &:before {
                content: attr(title);
                display: block;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                max-width: 100px;
                border-radius: 5px;
                background: $ifl-purple;
                color: $white;
                padding: 5px;
                opacity: 0;
                transition: all 0.15s ease-out;
                pointer-events: none;
                white-space: normal;
            }

            &:hover {
                color: $ifl-purple;
                &:before { opacity: 1; }
            }
        }

        &.status {
            margin-right: 1rem;
            margin-left: auto;
        }
    }
}


.notification-count {
  background: $ifl-purple;
  color: $white;
  font-size: 12px;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  right: 4px;
  padding: 2px;
}

.roster__notifications {
  padding: 1rem 3rem;
}

.notification {
  font-family: $font-primary;
  text-transform: none;
  background: $ifl-orange-4;
  border-radius: 5px;
  padding: 1rem;
}
