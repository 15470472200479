%sort {
    cursor: pointer;
    &:hover {
        border-bottom-color: $ifl-darkpurple-2;
    }
}

@mixin sort($class) {
    @extend %sort;
    .sort--#{$class} & {
        // @extend %sort;
        border-bottom-color: $ifl-purple;
    }
    .sort--#{$class}.down & {
        // @extend %sort;
        border-bottom-color: $ifl-purple;
    }
}

.roster {
    &__container {
        grid-area: roster;
        display: flex;
        flex-flow: column nowrap;
    }
    & {
        border-collapse: collapse;
    }
    &__spacer {
        width: 3rem;
    }
    &__header {
        th {
            vertical-align: bottom;
            border-bottom: 4px solid $ifl-darkpurple-4;
            padding: 2rem 1rem 0.5rem;
            text-align: center;

            &:first-child {
                text-align: left;
            }

            &.roster__calendar {
                padding: 2rem 0 0.5rem;
            }
            &.is-today { border-bottom-color: $ifl-purple; }
        }
    }
    &__empty {
        padding: 2rem;
        text-align: center;
    }
    &__rows {
        td {
            padding: 0.5rem 0;
            vertical-align: middle;

            &:nth-child(2) {
                .roster__row {
                    border-radius: 10px 0 0 10px;
                    padding-left: 1rem;
                }
            }
            &:nth-last-child(2) {
                .roster__row {
                    border-radius: 0 10px 10px 0;
                    padding-right: 1rem;
                }
            }
        }
    }
    &__row {
        display: flex;
        align-items: center;
        width: 100%;
        background: $white;
        min-height: 5rem;
        padding: 1rem 0;
    }
    &__name {
        .roster__row {
            font-size: 20px;
            font-weight: 700;
            padding: 0 1rem;
        }
        @include sort("last_name");
    }
    &__id {
        max-width: 7rem;
        .roster__row { justify-content: center; }
        @include sort("id");
    }
    &__daysatrfs {
        max-width: 7rem;
        .roster__row { justify-content: center; }
        @include sort("reading_stage_updated_at");
    }
    &__rfs {
        max-width: 7rem;
        .roster__row { justify-content: center; }
        @include sort("reading_stage");
    }
    &__programs {
        max-width: 7rem;
        .roster__row { justify-content: center; }
        @include sort("study_group");
    }
    &__goal {
        max-width: 7rem;
        .roster__row { justify-content: center; }
        @include sort("eli_conferences_goal");
    }
    &__calendar {
        min-width: 1.75rem;
        max-width: 2rem;
        div:not(.roster__row) { display: inline-block; }
        [class*=icon] {
            margin: 0 3px;
            width: 1.5rem;
            height: 2.5rem;
            padding-top: 1.5rem;
            background-position: top center;
            text-align: center;
        }
        &__month {
            font-size: 12px;
            display: block;
        }
        .roster__row {
            justify-content: center;
        }
        [data-roster-calendar-move] {
            cursor: pointer;
            opacity: 0.5;
            &:hover { opacity: 0.75; }
        }
    }
    &__ready {
        text-align: right;
        @include sort("ready");
        @media print {
          display: none;
        }
    }
    &__absent {
        text-align: right;
        @include sort("absent");
        @media print {
          display: none;
        }
    }
    &__actions {
        @media print {
          display: none;
        }
        button {
            font-family: $font-primary;
            background: $ifl-darkpurple-5;
            padding: 0.25rem 1rem;
            min-height: unset;
            border-radius: 0;
            margin: 0;
            display: inline-block;
            transition: all 0.15s ease-out;
            &:hover {
                background: $ifl-darkpurple-4;
            }
            & + button {
                border-left: 1px solid rgba(255,255,255,0.5);
            }
            &:first-child {
                margin-left: auto;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
            &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
            &.is-complete {
                background: $ifl-purple;
                color: $white;
            }
            &.is-missed {
                background: $ifl-orange;
                color: $white;
            }
        }
    }
    &__classrooms {
      display: flex;
      flex-flow: row wrap;
      padding: 0 2rem;
      justify-content: center;

      button {
        border-width: 0 0 4px 0;
        border-bottom: 4px solid $ifl-darkpurple-4;
        margin: 0;
        padding: 0.5rem 1rem;
        border-radius: 0;

        &:hover { border-bottom: 4px solid $ifl-darkpurple-2; }
        &.active { border-bottom: 4px solid $ifl-purple; }
      }
    }
}

.quickreport {
  & {
    padding: 1rem 3rem
  }
  h2 {
    font-family: $font-primary;
    font-size: 20px;
    text-align: center;
  }
  p { margin: 0 }
  &__note {
    width: 100%;
    margin: 0.5rem auto;
    min-height: 50px;
    font-family: $font-primary;
    padding: 0.5rem;
    border: none;
    background: rgba(255,255,255,0.5);
    border-radius: 10px;
  }
  &__button {
    font-family: $font-primary;
    background: $ifl-darkpurple-1;
    color: $white;
    padding: 0.25rem 1rem;
    min-height: unset;
    border-radius: 5px;
    margin: 0;
    display: inline-block;
    transition: all 0.15s ease-out;
    &:hover {
        background: $ifl-darkpurple;
    }
  }
}