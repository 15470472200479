// naming convention: BEM: block__element--modifier
@charset "UTF-8";

//-------------------------------------------------------------
//	BASE
//	Variables, Mixins, Utilities
//-------------------------------------------------------------

// Variables, Mixins, Utilities
@import "global/settings.variables";
@import "global/utilities.mixins";
@import "global/utilities.helpers";

// -------------------------------------------------------------
// 	GENERAL
// 	Global Styles
// -------------------------------------------------------------

// Base
@import "global/base.layout";
@import "global/base.icons";
@import "global/base.header";

// -------------------------------------------------------------
// 	MODULES
// 	Specific module Styles
// -------------------------------------------------------------

// shared

// pages
@import "components/component.roster";
@import "components/component.advancement";
@import "components/component.notes";
@import "components/component.offline";


// -------------------------------------------------------------
// 	TEMPORARY
// 	Temporary styles for demo and debugging
// -------------------------------------------------------------

@import "components/temp.v1tov2";

// DEBUG LAYOUT OUTLINES
//  * {
//    outline: 1px solid blue;
//  }
