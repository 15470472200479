$item-width: 13rem;
$item-height: 8rem;

.advancement {
    width: 100%;
    display: grid;
    grid-template-columns: $item-width*2 1fr;
    font-family: $font-primary;

    // display settings
    &__items {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
    }
    &__item {
        width: $item-width;
        min-height: $item-height;
        padding: 1rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column nowrap;
        text-align: center;

        &:nth-child(2),
        &:nth-child(4) {
            border-left: 1px solid $ifl-darkpurple-4;
        }
        &:nth-child(3),
        &:nth-child(4) {
            border-top: 1px solid $ifl-darkpurple-4;
        }
        &:hover:not(.current) .advancement__value,
        &:hover:not(.current) .advancement__label {
            color: $ifl-darkpurple-2;
        }
    }
    &__value {
        font-size: 50px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 0.5rem;
        transition: all 0.15s ease-out;
        .current & { color: $ifl-purple; }
    }
    &__label {
        transition: all 0.15s ease-out;
        font-weight: 700;
        .current & { color: $ifl-purple; }
    }

    // change settings
    &__choosers {
        border-left: 1px solid $ifl-darkpurple-4;
        padding: 2rem;
        text-align: center;
    }
    &__chooser {
        padding: 1rem;
    }
    &__title {
        font-weight: 700;
        margin-bottom: 0.5rem;
    }
    &__options {
        span {
            cursor: pointer;
            font-size: 30px;
            padding: 0.5rem;
            &.current { color: $ifl-purple; }
            &.readingLevelCircle {
                font-size: 10px;
                padding: 0;
                &.current { color: $white; }
            }
        }
    }
}
