.material-symbols-rounded {
    font-variation-settings:
        'FILL' 0,
        'wght' 500,
        'GRAD' 0,
        'opsz' 48
}

%icon {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.icon {
    &-student {
        &-empty {
            @extend %icon;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' fill='%23" + str-slice("#{$ifl-darkpurple-5}", 2) + "' width='48'%3E%3Cpath d='M24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z'/%3E%3C/svg%3E");
        }
        &-off {
            @extend %icon;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' fill='%23" + str-slice("#{$ifl-darkpurple-5}", 2) + "' width='48'%3E%3Cpath d='M15.45 32.55q.45.45 1.05.45.6 0 1.05-.45L24 26.1l6.5 6.5q.4.4 1.025.375.625-.025 1.025-.425.45-.45.45-1.05 0-.6-.45-1.05L26.1 24l6.5-6.5q.4-.4.375-1.025-.025-.625-.425-1.025Q32.1 15 31.5 15q-.6 0-1.05.45L24 21.9l-6.5-6.5q-.4-.4-1.025-.375-.625.025-1.025.425-.45.45-.45 1.05 0 .6.45 1.05L21.9 24l-6.5 6.5q-.4.4-.375 1.025.025.625.425 1.025ZM24 44q-4.25 0-7.9-1.525-3.65-1.525-6.35-4.225-2.7-2.7-4.225-6.35Q4 28.25 4 24q0-4.2 1.525-7.85Q7.05 12.5 9.75 9.8q2.7-2.7 6.35-4.25Q19.75 4 24 4q4.2 0 7.85 1.55Q35.5 7.1 38.2 9.8q2.7 2.7 4.25 6.35Q44 19.8 44 24q0 4.25-1.55 7.9-1.55 3.65-4.25 6.35-2.7 2.7-6.35 4.225Q28.2 44 24 44Z'/%3E%3C/svg%3E");
        }
        &-absent {
            @extend %icon;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' fill='%23" + str-slice("#{$ifl-orange}", 2) + "' width='48'%3E%3Cpath d='M15.45 32.55q.45.45 1.05.45.6 0 1.05-.45L24 26.1l6.5 6.5q.4.4 1.025.375.625-.025 1.025-.425.45-.45.45-1.05 0-.6-.45-1.05L26.1 24l6.5-6.5q.4-.4.375-1.025-.025-.625-.425-1.025Q32.1 15 31.5 15q-.6 0-1.05.45L24 21.9l-6.5-6.5q-.4-.4-1.025-.375-.625.025-1.025.425-.45.45-.45 1.05 0 .6.45 1.05L21.9 24l-6.5 6.5q-.4.4-.375 1.025.025.625.425 1.025ZM24 44q-4.25 0-7.9-1.525-3.65-1.525-6.35-4.225-2.7-2.7-4.225-6.35Q4 28.25 4 24q0-4.2 1.525-7.85Q7.05 12.5 9.75 9.8q2.7-2.7 6.35-4.25Q19.75 4 24 4q4.2 0 7.85 1.55Q35.5 7.1 38.2 9.8q2.7 2.7 4.25 6.35Q44 19.8 44 24q0 4.25-1.55 7.9-1.55 3.65-4.25 6.35-2.7 2.7-6.35 4.225Q28.2 44 24 44Z'/%3E%3C/svg%3E");
        }
        &-session {
            @extend %icon;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' fill='%23" + str-slice("#{$ifl-purple}", 2) + "' width='48'%3E%3Cpath d='m21.05 28.55-4.9-4.9q-.45-.45-1.1-.45-.65 0-1.1.45-.5.5-.5 1.175t.45 1.125l6.1 6.1q.4.4 1.05.4t1.05-.4l12-12q.45-.45.45-1.1 0-.65-.5-1.15-.45-.45-1.125-.45t-1.175.5ZM24 44q-4.25 0-7.9-1.525-3.65-1.525-6.35-4.225-2.7-2.7-4.225-6.35Q4 28.25 4 24q0-4.2 1.525-7.85Q7.05 12.5 9.75 9.8q2.7-2.7 6.35-4.25Q19.75 4 24 4q4.2 0 7.85 1.55Q35.5 7.1 38.2 9.8q2.7 2.7 4.25 6.35Q44 19.8 44 24q0 4.25-1.55 7.9-1.55 3.65-4.25 6.35-2.7 2.7-6.35 4.225Q28.2 44 24 44Z'/%3E%3C/svg%3E");
        }
    }
}