.is-hidden {
  display: none !important;
}

.is-invisible {
  visibility: hidden;
}

.is-overflow-hidden {
  overflow: hidden !important;
}

[disabled],
[disabled="true"],
.is-disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

// for events within buttons
button i,
button span {
  pointer-events: none;
}