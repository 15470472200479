// TYPOGRAPHY
@import url('https://fonts.googleapis.com/css?family=Quicksand:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

$font-primary: 'Quicksand', sans-serif;

// COLORS
$black: #000000;
$white: #ffffff;

// BRAND
$ifl-yellow: #FCCC00;
$ifl-yellow-1: #fdd633;
$ifl-yellow-2: #fdde5c;
$ifl-yellow-3: #fee685;
$ifl-yellow-4: #feefad;
$ifl-yellow-5: #fff7d6;

$ifl-darkpurple: #18083F;
$ifl-darkpurple-1: #463965;
$ifl-darkpurple-2: #6b6184;
$ifl-darkpurple-3: #89819d;
$ifl-darkpurple-4: #a39cb2;
$ifl-darkpurple-5: #d1ced8;

$ifl-purple: #703EEF;
$ifl-purple-1: #8d65f2;
$ifl-purple-2: #a384f5;
$ifl-purple-3: #a98bf6;
$ifl-purple-4: #c6b2f9;
$ifl-purple-5: #e2d8fc;

$ifl-orange: #F36B25;
$ifl-orange-1: #f58951;
$ifl-orange-2: #f7a074;
$ifl-orange-3: #f8a67c;
$ifl-orange-4: #fac4a8;
$ifl-orange-5: #fde1d3;


// SETTINGS
$background:#e7e9f5; //linear-gradient(to bottom, $ifl-darkpurple-4, $ifl-darkpurple-5 40%); edebf1
$font-primary-color: $black;

$header-height: 100px;