//To uncollapse parent divs with floating children
@mixin uncollapse() {
  &:before,
  &:after {
    display: table;
    content: " ";
  }
  &:after {
    clear: both;
  }
}
