$size: 7px;

%indicator {
    width: $size;
    height: $size;
    min-height: $size;
    border-radius: 50%;
    margin: 3px;
    .offline__legend & {
        margin: 6px 0 10px;
    }
}

.offline-indicator {
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 1.75rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 45px;
    padding-right: 20px;

    .offline__legend & {
        position: relative;
        margin-top: 0;
        height: auto;
        flex-flow: row nowrap;
        background:white;
        justify-content: space-between;
        padding: 5px 4rem;
    }
    i {
        display: block;
        color: $ifl-darkpurple-4;
    }

    .offline {
        @extend %indicator;
        background: $ifl-orange; 
    }
    .pubnub {
        @extend %indicator;
        background: $ifl-yellow; 
    }
    .sync {
        @extend %indicator;
        background: $ifl-purple; 
    }
}